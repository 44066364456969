import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import styles from './styles/About.module.scss';

const NoticeDetail = () => {
    const { id } = useParams();  // URL에서 id 가져오기
    const location = useLocation();
    const navigate = useNavigate();
    const notice = location.state; // Notice.js에서 전달된 데이터

    if (!notice) {
        return (
            <div className={styles.wrapper} style={{ paddingTop: '3rem', textAlign: 'center' }}>
                <h2>게시글을 찾을 수 없습니다.</h2>
            </div>
        );
    }

    return (
        <Fragment>
            <div className={styles.wrapper} style={{
                paddingTop: '3rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                {/* 제목 영역 */}
                <div className={styles.contentBox} style={{
                    borderBottom: "2px solid #ddd",
                    paddingBottom: "1rem",
                    marginBottom: "2rem"
                }}>
                    <h2 style={{ fontSize: "1.8rem", fontWeight: "bold", marginBottom: "0.5rem" }}>
                        {notice.title}
                    </h2>
                    <p style={{ fontSize: "0.9rem", color: "#666" }}>
                        게시일: {notice.date}
                    </p>
                </div>

                {/* 본문 내용 */}
                <div className={styles.contentBox} style={{
                    backgroundColor: "#fff",
                    padding: "2rem",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    maxWidth: "800px",
                    lineHeight: "1.8",
                    fontSize: "1.1rem",
                    color: "#333",
                    textAlign: "justify",
                    whiteSpace: "pre-line"
                }}>
                    {notice.content}
                

                {/* 날짜 */}
                <div style={{
                    marginTop: "2rem",
                    fontSize: "1.2rem",
                    fontWeight: "bold"
                }}>
                    {notice.date}
                </div>

                {/* 회사명 및 주소 */}
                <div style={{
                    marginTop: "1.5rem",
                    fontSize: "1.2rem",
                    fontWeight: "bold"
                }}>
                    엠디하이 주식회사
                </div>

                <div style={{
                    fontSize: "1rem",
                    marginTop: "0.5rem",
                    color: "#333"
                }}>
                    대전광역시 유성구 가정로 218, 302-1-3호(가정동, 융합기술연구생산센터)
                </div>

                {/* 청산인 */}
                <div style={{
                    marginTop: "1rem",
                    fontSize: "1rem",
                    fontWeight: "bold"
                }}>
                    청산인 정재연
                </div>
                </div>
                {/* 뒤로 가기 버튼 */}
                <button
                    onClick={() => navigate(-1)}
                    style={{
                        marginTop: "2rem",
                        marginBottom: "2rem",
                        padding: "10px 20px",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#007BFF",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "0.2s ease-in-out"
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = "#0056b3"}
                    onMouseOut={(e) => e.target.style.backgroundColor = "#007BFF"}
                >
                    뒤로가기
                </button>
            </div>
        </Fragment>
    );
};

export default NoticeDetail;
