import { Reset } from "styled-reset";
import { Suspense, useState } from "react";
import { Route, Routes, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Main from "./Page/Main";
import About from "./Page/About";
import Business from "./Page/Business";
import Career from "./Page/Career";
import Contact from "./Page/Contact";
import RND from "./Page/RND";
import Error from "./Page/Error";
import Notice from "./Page/Notice";
import NoticeDetail from "./Page/NoticeDetail";
import "./mdhi.scss"; // SCSS 파일 불러오기

const Layout = () => (
  <div className="Global">
    <Header />
    <Suspense fallback={<div>LOADING</div>}>
      <Outlet />
    </Suspense>
    <Footer />
  </div>
);

const ScrollTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return <>{children}</>;
};

function App() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState([true, true]); // 각 공지 팝업 상태 관리

  // 공지사항 데이터
  const notices = [
    {
      id: 1,
      title: "해산 및 채권신고 공고(1차)",
      date: "2025. 03. 19",
      content: "  당 회사는 2025년 03월 10일 임시주주총회에서 해산을 결의하고 현재 청산절차를 진행 중인 바, 당 회사에 대하여 이의가 있거나 채권이 있으신 분은 본 공고게재 익일로부터 2개월 이내에 이의나 그 채권액을 신고하여 주시기를 바라며, 만일 위 기간 내에 이의나 신고가 없으면 청산에서 제외됨을 공고합니다."
    },
    {
      id: 2,
      title: "해산 및 채권신고 공고(2차)",
      date: "2025. 03. 20",
      content: "  당 회사는 2025년 03월 10일 임시주주총회에서 해산을 결의하고 현재 청산절차를 진행 중인 바, 당 회사에 대하여 이의가 있거나 채권이 있으신 분은 본 공고게재 익일로부터 2개월 이내에 이의나 그 채권액을 신고하여 주시기를 바라며, 만일 위 기간 내에 이의나 신고가 없으면 청산에서 제외됨을 공고합니다."
    }
  ];

  return (
    <>
      <Reset />
      <ScrollTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
            <Route path="/biz" element={<Business />} />
            <Route path="/career" element={<Career />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/notice/:id" element={<NoticeDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rnd" element={<RND />} />
          </Route>
          <Route path="/*" element={<Error status={404} />} />
        </Routes>
      </ScrollTop>

      {/* 공지사항 팝업 (자동 정렬) */}
      <div className="noticeContainer">
        {notices.map((notice, index) =>
          showPopup[index] && (
            <div
              key={notice.id}
              className="noticeCard"
              style={{ bottom: `${20 + index * 160}px` }} // 카드 간격 자동 배치
              onClick={() => navigate(`/notice/${notice.id}`, { state: notice })}
            >
              <div className="cardHeader">
                <span>📢 공지사항</span>
                <button
                  className="closeButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPopup((prev) => {
                      const newPopup = [...prev];
                      newPopup[index] = false;
                      return newPopup;
                    });
                  }}
                >
                  ×
                </button>
              </div>
              <div className="cardContent">
                <h4 className="cardTitle">{notice.title}</h4>
                <p className="cardDate">{notice.date}</p>
                <p className="cardDescription">{notice.content}</p>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default App;
