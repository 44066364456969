import { Fragment } from 'react';
import SubTitle from "../Components/SubTitle";
import TextField from '@mui/material/TextField';
import { Checkbox } from "@mui/material";
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import styles from '../mdhi.module.scss';
import styles from './styles/About.module.scss';


const Contact = () => {
    const form = useRef();
    const agree = useRef();

      const card = (
        <React.Fragment>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            입력하신 정보 및 시스템 정보가 MDHi에 전송될 수 있습니다.
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            제공해 주신 정보는 개인정보처리방침 및 서비스 이용약관에 따라 기술 문제를 해결하고 서비스를 개선하는데 사용됩니다.
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            이용자는 개인정보의 수집과 이용에 대해 동의를 거부할 권리가 있으며, 동의를 거부하는 경우 MDHi의 서비스 이용이 제한 될 수 있습니다.
            </Typography>
          </CardContent>
        </React.Fragment>
      );

    const sendEmail = (e) => {
        e.preventDefault();
        // form 초기화
        emailjs.sendForm('service_cwmja0n', 'template_4sgagqw', form.current, 'r8eMd4OILA8m8Iiy-')
        .then((result) => {
            console.log(result.text);
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        })
    }

    function MailForm() {
      return(
        <div className={ styles.contentBox }>
        <form ref = { form } onSubmit={ sendEmail }>
        <div>
          <label>Name</label><br/><br/>
          <TextField label = 'required' required name = 'user_name'></TextField>
          <br/><br/>
          <label>Email</label><br/><br/>
          <TextField label = 'required' required name = 'user_email'></TextField><br/><br/>
          <label>Facility/Company Name</label><br/><br/>
          <TextField label = 'required' required name = 'company_name'></TextField><br/><br/>
          <label>Comment or Message</label><br/><br/>
          <div style = {{ width : '100%' }}>
            <TextField fullWidth multiline rows={4} label = 'required' required name = 'message'>
              </TextField><br/><br/>
            <label>개인정보 수집 및 이용 동의</label><br/><br/>
            <Checkbox required name = 'agree' id = 'agree' ref = { agree } />
              <label>동의</label><br/>
            <Checkbox name = 'disagree' id = 'disagree' ref = { agree }/>
              <label>거부</label><br/><br/>
            <Button variant='outlined' type='submit'>Send Email</Button>
          </div>
        </div>
          <br/>
          <div>
            <Box sx={{ width : '100%' }}>
                <Card variant="outlined">{card}</Card>
            </Box>
          </div>
        </form>
        </div>
      )
    }

    return(
        <Fragment>
            <SubTitle loc='Contact Us'/>
            <div className={styles.wrapper} style={{paddingTop:'3rem'}}>
                <div className={styles.contentBox}>
                        <div className={styles.subTitle}>
                            Interested in our Company
                        </div>
                        <div className={styles.imageTextContent}>
                            <span><MailForm /></span>
                        </div>
              </div>
              {/* 본사 */}
              <div className={styles.map_Summary}>
                  <div className={styles.subTitle}>
                      <span>Contact Us</span>
                  </div>
                  <div className={styles.text_MapContent}>
                      <div className={styles.text_Area}>
                      <h1 className={styles.h1}>Location (본사)</h1>
                      <span>
                        {'\n대전광역시 유성구 가정로 218, 3층 302-1-3호(융합기술연구생산센터)\n'}
                      </span>
                      <h1 className={styles.h1}>{'\n\nTel'}</h1>
                      <span>
                        {'+82-70-8802-0246\n'}
                      </span>
                      <h1 className={styles.h1}>{'\n\nE-Mail'}</h1>
                      <span>
                        {'contact@mdhi.co.kr'}
                      </span>
                      </div>
                        <div className={styles.iframe}>
                            <iframe
                            width='100%'
                            height='100%'
                            style = {{ border : 0 }}
                            loading='lazy'
                            allowFullScreen
                            referrerPolicy='no-referrer-when-downgrade'
                            title="본사정보"
                            src='https://www.google.com/maps/embed/v1/place?key=AIzaSyDmeK0AkTduNVkOxY1vecboO_Rkv_MO4rg
                              &q=place_id:ChIJfygbxjZKZTURjQ7ppo1FuI0'>
                            </iframe>
                            
                        </div>
                  </div>
                  {/* 연구소 */}
                  <div className={styles.text_MapContent}>
                      <div className={styles.text_Area}>
                      <h1 className={styles.h1}>Location (연구소)</h1>
                      <span>
                        {'\n수원시 영통구 월드컵로193번길 52-3 원천빌딩 4층\n'}
                      </span>
                      <h1 className={styles.h1}>{'\n\nTel'}</h1>
                      <span>
                        {'+82-31-211-1020\n'}
                      </span>
                      <h1 className={styles.h1}>{'\n\nE-Mail'}</h1>
                      <span>
                        {'contact@mdhi.co.kr'}
                      </span>
                      </div>
                        <div className={styles.iframe}>
                            <iframe
                            width='100%'
                            height='100%'
                            style = {{ border : 0 }}
                            loading='lazy'
                            allowFullScreen
                            referrerPolicy='no-referrer-when-downgrade'
                            title='연구소정보'
                            src='https://www.google.com/maps/embed/v1/place?key=AIzaSyDmeK0AkTduNVkOxY1vecboO_Rkv_MO4rg
                            &q=place_id:ChIJsYSIZlRbezURpqen9dkhXCE'>
                            </iframe>
                        </div>
                  </div>
                </div>

            <br/>
            </div>
        </Fragment>
    )
}

export default Contact;