import { useNavigate } from "react-router-dom";
import SubTitle from "../Components/SubTitle";
import Signature from "../IMG/Main/Signature.png";
import styles from './styles/About.module.scss';
import { Fragment } from 'react';

const Notice = () => {
    const navigate = useNavigate();

    // Notice 데이터 (content 추가)
    const notices = [
        {
            id: 1,
            title: "해산 및 채권신고 공고(1차)",
            date: "2025. 03. 19",
            tag: "#announcement",
            content: "당 회사는 2025년 03월 10일 임시주주총회에서 해산을 결의하고 현재 청산절차를 진행 중인 바, 당 회사에 대하여 이의가 있거나 채권이 있으신 분은 본 공고게재 익일부터 2개월 이내에 이의나 그 채권액을 신고하여 주시기를 바랍니다. 만일 위 기간 내에 이의나 신고가 없으면 청산에서 제외됨을 공고합니다."
        },
        {
            id: 2,
            title: "해산 및 채권신고 공고(2차)",
            date: "2025. 03. 20",
            tag: "#announcement",
            content: "당 회사는 2025년 03월 10일 임시주주총회에서 해산을 결의하고 현재 청산절차를 진행 중인 바, 당 회사에 대하여 이의가 있거나 채권이 있으신 분은 본 공고게재 익일부터 2개월 이내에 이의나 그 채권액을 신고하여 주시기를 바랍니다. 만일 위 기간 내에 이의나 신고가 없으면 청산에서 제외됨을 공고합니다."
        },
    ];

    const handleClick = (notice) => {
        navigate(`/notice/${notice.id}`, { state: notice });
    };

    return (
        <Fragment>
            <SubTitle loc="Notice" />
            <div className={styles.wrapper} style={{ paddingTop: '3rem' }}>
                <div className={styles.contentBox}>
                    <div className={styles.subTitle}>
                        <span>Notice</span>
                    </div>
                </div>

                <div className={styles.contentBox}>
                    <div className={styles.imageTextContent} style={{
                        display: 'flex',
                        gap: '1.5rem',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '4rem'
                    }}>
                        {notices.map(notice => (
                            <div
                                key={notice.id}
                                onClick={() => handleClick(notice)}
                                style={{
                                    width: '300px',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    transition: '0.2s ease-in-out',
                                    backgroundColor: '#fff',
                                    overflow: 'hidden',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    textAlign: 'left',
                                    border: '1px solid #e0e0e0'
                                }}
                            >
                                <div style={{
                                    width: '100%',
                                    height: '180px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f5f5f5'
                                }}>
                                    <img src={Signature} alt="Notice Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                </div>

                                <div style={{ padding: '1rem' }}>
                                    <div style={{ fontSize: '0.8rem', color: '#888', marginBottom: '0.5rem' }}>
                                        Announcement
                                    </div>
                                    <div style={{ fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>
                                        {notice.title}
                                    </div>
                                    <div style={{ fontSize: '0.9rem', color: '#666', marginBottom: '1rem' }}>
                                        {notice.date}
                                    </div>
                                    <div style={{
                                        fontSize: '0.8rem',
                                        color: '#444',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        border: '1px solid #ddd',
                                        display: 'inline-block'
                                    }}>
                                        {notice.tag}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Notice;
